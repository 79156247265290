import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { motion } from "framer-motion";
import logo from '../assets/logo.png';


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log(menuOpen)
  };

  
 
  
  const handleScroll = () => {
  
    setScrollY(window.scrollY);
  };
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);
  

  return (
    <div className={`fixed ${scrollY>2 && 'bg-white shadow-2xl'} duration-300 z-[999]`}>
      <div id="topbar" className="p-2  flex flex-col bg-transparent items-center  w-screen " >
        <div className={`container pt-0  w-screen origin-top ${scrollY>2 ?'scale-y-0':'scale-y-100'} duration-300`}>
          <div className="flex justify-end  px-20 space-x-4 hidden md:flex  ">
            <a
              href="#"
              className="text-gray-700 px-2 hover:text-cyan-400 transition duration-300 ease-in-out"
            >
              <FaTwitter size={15} />
            </a>
            <a
              href="#"
              className="text-gray-700 px-2 hover:text-cyan-400 transition duration-300 ease-in-out"
            >
              <FaFacebookF size={15} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/bitlogics-sol/"
              className="text-gray-700 px-2 hover:text-cyan-400 transition duration-300 ease-in-out"
            >
              <FaLinkedinIn size={15} />
            </a>
            <a
            target="_blank"
              href="https://www.instagram.com/bitlogics.tech/"
              className="text-gray-700 px-2 hover:text-cyan-400 transition duration-300 ease-in-out"
            >
              <FaInstagram size={15} />
            </a>
          </div>
        </div>
     

      <div className="container  h-12   px-4 md:px-16 w-screen" >
        <div className="  flex flex-row justify-between items-center ">
          <div className="">
            <h1 className="text-light">
              <a href="#intro" className="scrollto">
                <span className="uppercase text-4xl tracking-wide "  style={{ color: '#413e66' }}><img  style={{ width: '48px' }} src={logo} alt='BitLogics'/></span>
              </a>
            </h1>
          </div>

          {/* Hamburger menu for mobile */}
          <div className="lg:hidden">
          <button
        onClick={toggleMenu}
        className="text-gray-700 px-2 py-1 focus:outline-none"
      >
        {menuOpen ? <RxCross2 color='' /> : <RxHamburgerMenu />}
      </button>
          </div>

          {/* Main navigation for desktop */}
          <nav className="hidden lg:flex items-center  font-semibold pt-1 px-6">
            <ul className="flex space-x-12">
              <li className="mr-4 text-purple-500">
                <a
                  href="#intro"
                  className=" hover:text-cyan-400 transition duration-300 ease-in-out" 
                >
                  Home
                </a>
              </li>
              <li className="mr-4">
                <a
                  href="#aboutus"
                  className="text-purple-950 hover:text-cyan-400 transition duration-300 ease-in-out"
                >
                  About Us
                </a>
              </li>
              <li className="mr-4">
                <a
                  href="#services"
                  className="text-purple-950 hover:text-cyan-400 transition duration-300 ease-in-out"
                >
                  Services
                </a>
              </li>
              <li className="mr-4">
                <a
                  href="#portfolio"
                  className="text-purple-950 hover:text-cyan-400 transition duration-300 ease-in-out"
                >
                  Portfolio
                </a>
              </li>
              <li className="mr-4">
                <a
                  href="#team"
                  className="text-purple-950 hover:text-cyan-400 transition duration-300 ease-in-out"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  href="#footer"
                  className="text-purple-950 hover:text-cyan-400 transition duration-300 ease-in-out"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>

          {/* Mobile navigation */}
          {menuOpen && (
            <div className="lg:hidden absolute top-16 right-40 left-0 bg-indigo-500  h-screen py-4 mt-1">
              <ul className="flex flex-col items-start space-y-4 pr-4   float-left pl-10 font-bold ">
                <li>
                  <a
                    href="#intro"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out shadow-lg   "
                    onClick={toggleMenu}
                  >
                    Home
                  </a>
                 
                </li>
                <li>
                  <a
                    href="#aboutus"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out shadow-lg"
                    onClick={toggleMenu}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#services"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out"
                    onClick={toggleMenu}
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="#portfolio"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out shadow-lg"
                    onClick={toggleMenu}
                  >
                    Portfolio
                  </a>
                </li>
                <li>
                  <a
                    href="#team"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out"
                    onClick={toggleMenu}
                  >
                    Team
                  </a>
                </li>
                <li>
                  <a
                    href="#footer"
                    className="text-white hover:text-cyan-400 transition duration-300 ease-in-out shadow-lg"
                    onClick={toggleMenu}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      
    </div> </div>
  );
};

export default Header;
